@font-face {
  font-family: 'Causten Round';
  src: url('./styles/fonts/causten-round/causten-round-regular-webfont.woff2') format('woff2'),
    url('./styles/fonts/causten-round/causten-round-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('./styles/fonts/causten-round/causten-round-regular-oblique-webfont.woff2')
      format('woff2'),
    url('./styles/fonts/causten-round/causten-round-regular-oblique-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('./styles/fonts/causten-round/causten-round-medium-webfont.woff2') format('woff2'),
    url('./styles/fonts/causten-round/causten-round-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('./styles/fonts/causten-round/causten-round-bold-webfont.woff2') format('woff2'),
    url('./styles/fonts/causten-round/causten-round-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten';
  src: url('./styles/fonts/causten/causten-regular-webfont.woff2') format('woff2'),
    url('./styles/fonts/causten/causten-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Causten';
  src: url('./styles/fonts/causten/causten-medium-webfont.woff2') format('woff2'),
    url('./styles/fonts/causten/causten-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Causten';
  src: url('./styles/fonts/causten/causten-bold-webfont.woff2') format('woff2'),
    url('./styles/fonts/causten/causten-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}
